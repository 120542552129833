import styled from "styled-components";



export const Box = styled.div<{ height: number }>`
  --nav-height: 80px;
  --height: ${props => props.height +200}px;
  --min-height: calc(var(--height) - var(--nav-height));
  min-height: var(--height);
  max-width: 876px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  gap: 48px;
  padding: 32px;
`;


export const TopBox = styled.div`
  max-width: 876px;
  display: flex;
  flex-direction: column;

`;




export const OuterDiv = styled.div`
  position: absolute;
  margin-left: auto;
  width: 137px;
  height: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  transform: rotate(-12.56deg);
  transform-origin: 0 0;
  background: #594DED;
  border-radius: 152px;
  align-self: self-start;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
`;


export const InnerDiv = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
  word-wrap: break-word;
`;

export const TitleContainer = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; 
  gap: 24px;
`;


export const TitleBox = styled.div`
  width: 100%;
  max-width: 764px;
`;

export const Title = styled.div`
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  word-wrap: break-word;

  @media (max-width: 700px) {
  font-size: 40px;
  line-height: 52px;
    } 
`;


export const SubTitleBox = styled.div`
  min-height: 90px;
  width: 100%;
  max-width: 625px;
`;


export const SubTitle = styled.div`
  font-size: 20;
  line-height: 30px;
  font-weight: 400;
`;

export const BottomContainer = styled.div`
    height: 278px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;



export const MediaContainer = styled.picture`
height: 238px;
  img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
`;





