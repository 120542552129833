import FrameBox from "../../components/FrameBox";
import { CenterContainer } from "../../components/FrameBox.styles";
import * as Styled from "./Frame4.styled";


const largeImage = "./frame4-saze01.png";
const mediumImage = './frame4-saze02.png';
const smallImage = './frame4-mobile.png';


const Frame4 = () => {

    return (
        <FrameBox>
            <CenterContainer>

                <Styled.Box height={window.innerHeight}>
                    <Styled.TopBox>

                        <Styled.OuterDiv>
                            <Styled.InnerDiv>Coming SOON</Styled.InnerDiv>
                        </Styled.OuterDiv>

                        <Styled.TitleContainer>
                            <Styled.TitleBox>
                                <Styled.Title>
                                    It's easier with AI: Generate your 3D web space 
                                </Styled.Title>
                            </Styled.TitleBox>


                            <Styled.SubTitleBox>
                                <Styled.SubTitle>
                                    Worried about blank pages? No need! Begin crafting
                                    your 3D web space with a straightforward text prompt.
                                    Write in any language, and in just a minute,
                                    your 3D web space will be ready.
                                </Styled.SubTitle>
                            </Styled.SubTitleBox>

                        </Styled.TitleContainer>
                    </Styled.TopBox>

                    <Styled.BottomContainer>
                        <Styled.MediaContainer>


                            <source media="(max-width: 576px)" srcSet={mediumImage} />
                            <source media="(max-width: 768px)" srcSet={mediumImage} />
                            <source media="(min-width: 769px)" srcSet={largeImage} />
                            <img src={largeImage} alt="frame 4 image" />

                        </Styled.MediaContainer>

                    </Styled.BottomContainer>

                </Styled.Box>
            </CenterContainer>
        </FrameBox>
    )
}

// export default Frame4;


// import { FunctionComponent } from 'react';
// import styles from './Frame.module.css';


// const Frame4:FunctionComponent = () => {
//   	return (
//     		<div className={styles.frameParent}>
//       			<div className={styles.brandframeWrapper}>
//         				<div className={styles.brandframe}>
//           					<b className={styles.itsEasierWithContainer}>
//             						<p className={styles.itsEasierWith}>{`It's easier with AI: Generate your 3D `}</p>
//             						<p className={styles.itsEasierWith}>web space</p>
//           					</b>
//           					<div className={styles.worriedAboutBlankPagesNoWrapper}>
//             						<div className={styles.worriedAboutBlank}>{`Worried about blank pages? No need! Begin crafting your 3D web space with a straightforward text prompt. Write in any language, and in just a minute, your 3D web space will be ready. `}</div>
//           					</div>
//         				</div>
//       			</div>
//       			{/* <img className={styles.frameChild} alt="" src="Frame 1261155394.png" />
//       			<div className={styles.frameGroup}>
//         				<div className={styles.frameWrapper}>
//           					<div className={styles.frameContainer}>
//             						<div className={styles.vectorWrapper}>
//               							<img className={styles.frameItem} alt="" src="Ellipse 1.svg" />
//             						</div>
//             						<div className={styles.hiTellMe}>HI! Tell me about your business!</div>
//           					</div>
//         				</div>
//         				<div className={styles.enterUrlParent}>
//           					<div className={styles.enterUrl}>
//             						<div className={styles.writeYourAnswerWrapper}>
//               							<div className={styles.writeYourAnswer}>Write Your Answer</div>
//             						</div>
//             						<div className={styles.submitGen}>
//               							<div className={styles.vectorContainer}>
//                 								<img className={styles.vectorIcon} alt="" src="Vector.svg" />
//               							</div>
//             						</div>
//           					</div>
//           					<div className={styles.frameDiv}>
//             						<div className={styles.groupParent}>
//               							<img className={styles.frameInner} alt="" src="Group 1.svg" />
//               							<div className={styles.writeYourAnswer}>Help me Answer</div>
//             						</div>
//             						<div className={styles.groupParent}>
//               							<img className={styles.groupIcon} alt="" src="Group 3.svg" />
//               							<div className={styles.writeYourAnswer}>SkipQuestion</div>
//             						</div>
//             						<div className={styles.groupParent}>
//               							<img className={styles.groupIcon} alt="" src="Group 3.svg" />
//               							<div className={styles.writeYourAnswer}>Skip to platform</div>
//             						</div>
//           					</div>
//         				</div>
//         				<img className={styles.frameChild2} alt="" src="Vector 5.svg" />
//       			</div> */}
//     		</div>);
// };

export default Frame4;
